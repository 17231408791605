
































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { AktivitasTatalaksana } from "../models/aktivitasTatalaksana";
import AktivitasTatalaksanaFM from "../models/aktivitasTatalaksanaForm";
import { Komponen } from "@/apps/penyakit/models/komponen";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "AktivitasTatalaksanaForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    KomponenModalForm: () => import("@/apps/penyakit/components/KomponenModalForm.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isTatalaksanaModalFormActive = ref(false);
    const namaKomponen = ref("");
    const form = new AktivitasTatalaksanaFM();
    const paramsId = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const aktivitasTatalaksana = composition.instance as AktivitasTatalaksana;
    const aktivitasId = computed(() => {
      return (
        router.currentRoute.params.aktivitasId ??
        aktivitasTatalaksana.aktivitas?.id
      );
    });

    const setTatalaksana = (tatalaksana?: Komponen) => {
      aktivitasTatalaksana.tatalaksana =
        tatalaksana ?? aktivitasTatalaksana.tatalaksana;
      composition.validate("tatalaksana");
      isTatalaksanaModalFormActive.value = false;
    };
    return {
      // Data
      isTatalaksanaModalFormActive,
      aktivitasTatalaksana,
      aktivitasId,
      namaKomponen,

      // Composition
      ...composition,

      // Method
      setTatalaksana,
    };
  },
});

import FormModel from "@/apps/core/models/formModel";
import router from "@/router";
import {
  AktivitasTatalaksana,
  AktivitasTatalaksanaAPI,
} from "./aktivitasTatalaksana";

export default class AktivitasKlinisFM extends FormModel {
  constructor() {
    super(
      new AktivitasTatalaksanaAPI(),
      new AktivitasTatalaksana(),
      ["diagnosis", "tatalaksana"],
      []
    );
  }

  getPayload() {
    const payload = super.getPayload();
    payload.aktivitas = router.currentRoute.params.aktivitasId;
    return payload;
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}

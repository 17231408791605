import { API } from "@/apps/core/api";
import ViewModel from "@/apps/core/models/viewModel";
import { Komponen } from "@/apps/penyakit/models/komponen";
import { AktivitasKlinis } from "./aktivitasKlinis";
import { AktivitasKomponen } from "./aktivitasKomponen";


export class AktivitasKeterampilan extends AktivitasKomponen {
  keterampilan: Komponen | null = null;
  kompetensi: string | null = null;
  verifikasi: string | null = null;
  waktuVerifikasi: string | null = null;
  hasil: string | null = null;

  loadData(json: Record<string, any>) {
    const aktivitasKlinis = new AktivitasKlinis();
    const keterampilan = new Komponen();
    keterampilan.loadData(json.keterampilan);
    aktivitasKlinis.loadData(json);
    json.aktivitas = aktivitasKlinis;
    json.keterampilan = keterampilan;
    super.loadData(json);
  }

  // getPayload() {
  //   const payload = super.getPayload();
  //   payload.aktivitas = router.currentRoute.params.aktivitasId;
  //   if (payload.hasil === null) payload.hasil = "";
  //   return payload;
  // }
}

export class AktivitasKeterampilanAPI extends API {
  static modelPath = "/aktivitas/keterampilan/";

  constructor() {
    super(AktivitasKeterampilanAPI.modelPath);
  }
}

export default class AktivitasKeterampilanVM extends ViewModel {
  constructor() {
    super(new AktivitasKeterampilanAPI(), new AktivitasKeterampilan());
  }
}
